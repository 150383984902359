import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from '../../../../components/shared';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import images from '../../../../config/images';
import toast from '../../../../utils/toast';
import {
  FieldError,
  ModalTitle,
  FieldIconLabel,
  formFields,
  iconList,
  removeNullValues,
} from '../../components/ICP/utils';
import LocationAutocomplete from './LocationAutocomplete';
import { useStyles } from '../../components/ICP/style';
import InputSelect from '../../../../components/shared/Form/Select';
import AutocompleteField from './AutocompleteField';

const removeOptionListCheck = ['jobTitle', 'technologyUsed'];

const createICPContainer = ({
  setShowICPForm,
  showICPForm,
  saveICP,
  updateICP,
  getICPById,
  fetchJobList,
  fetchTechUsed,
  ...props
}) => {
  const globalConfig = useSelector((state) => state.app.globals);
  const classes = useStyles();
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const [autocompleteList, setAutocompleteList] = useState([]);
  const [subSelectList, setSubSelectList] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [editICPValue, setEditICPValue] = useState({});
  const updateOptionList =
    globalConfig?.icpList?.map(({ key, title, ...rest }) => ({
      value: key,
      label: title,
      icon: (
        <img
          src={images.icp?.[key] ?? images.icp.defaultIcon}
          alt=""
          width={'24px'}
          height={'24px'}
          style={{ marginRight: '8px' }}
        />
      ),
      ...rest,
    })) || [];

  const filterExistFieldList =
    updateOptionList.filter((field) => !formFields.some((e) => e.fieldName === field.value)) || [];

  const [formValues, setFormValues] = useState({});

  const getCurrentFormList = useMemo(() => {
    return { formFields };
  }, [formFields]);

  const getModalTitle = useMemo(() => {
    if (showICPForm.edit) {
      return 'Edit Ideal Customer Profile (ICP)';
    }
    if (showICPForm.clone) {
      return 'Clone Ideal Customer Profile (ICP)';
    }
    return 'Create a new Ideal Customer Profile (ICP)';
  }, [showICPForm]);

  const fetchData = async () => {
    if ((showICPForm.edit || showICPForm.clone) && showICPForm.id) {
      setLoadingPage(true);
      try {
        const res = await getICPById(showICPForm.id);
        setEditICPValue(res.icp);
        setLoadingPage(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingPage(false);
      }
    } else {
      setEditICPValue({});
    }
  };

  useEffect(() => {
    fetchData();
  }, [showICPForm]);

  useEffect(() => {
    getCurrentFormList?.formFields.forEach((item) => {
      if (item.fieldName.includes('type') && item.fieldOptions && item.fieldOptions?.length > 0) {
        setFormValues({ ...formValues, [item.fieldName]: item.fieldOptions[0] });
      }
    });
  }, []);

  useEffect(() => {
    if (editICPValue) {
      const cleanedData = removeNullValues(editICPValue);
      const updatedAutocompleteList = Object.keys(cleanedData).map((key, index) => {
        const findTypeData = filterExistFieldList?.find((v) => v?.value === key);
        if (!findTypeData) return;
        const newItemData = {
          ...findTypeData,
          options: removeOptionListCheck?.includes(findTypeData.value)
            ? []
            : typeof findTypeData?.option === 'string'
            ? [{ label: findTypeData.option, value: findTypeData.option }]
            : Array.isArray(findTypeData?.option)
            ? findTypeData.option.map((opt) => ({ label: opt, value: opt }))
            : [],
        };
        return { id: Date.now() + index, value: editICPValue[key], ...newItemData };
      });
      const filteredArray = updatedAutocompleteList?.filter((item) => item !== undefined);
      setAutocompleteList(filteredArray);
      setSubSelectList(filteredArray);

      const initialSelectedValues = Object.keys(cleanedData).reduce((acc, key) => {
        const itemData = filteredArray.find((item) => item.value === key);
        if (itemData) {
          acc[itemData.value] = Array.isArray(cleanedData[key]?.value)
            ? cleanedData[key]
            : cleanedData[key];
        }
        return acc;
      }, {});
      setSelectedValues(initialSelectedValues);
    }
  }, [editICPValue]);

  const getInitialValues = () => {
    if (!showICPForm?.edit && !showICPForm?.clone) return {};
    const currentFormListValue = getCurrentFormList?.formFields.reduce((initialValues, item) => {
      if (item.fieldName === 'profileType') {
        const findProfileType = iconList?.find((v) => v?.value === editICPValue?.[item.fieldName]);
        return {
          ...initialValues,
          [item.fieldName]: { value: findProfileType?.value, label: findProfileType?.label },
        };
      }
      if (item.fieldName === 'name' && showICPForm.clone) {
        return {
          ...initialValues,
          [item.fieldName]: `Copy of ${editICPValue?.[item.fieldName]}`,
        };
      }
      return {
        ...initialValues,
        [item.fieldName]:
          editICPValue !== undefined
            ? editICPValue?.[item.fieldName]
            : formValues?.[item.fieldName] || '',
      };
    }, {});
    const subSelectListValue = subSelectList.reduce((initialValues, item) => {
      if (item.type === 'multiple') {
        return {
          ...initialValues,
          [item.value]: Array.isArray(selectedValues[item.value]?.value)
            ? selectedValues[item.value].value.map((val) => ({
                label: val,
                value: val,
              }))
            : [],
        };
      }
      if (item.type === 'single') {
        return {
          ...initialValues,
          [item.value]: selectedValues[item.value]?.value
            ? {
                value: selectedValues?.[item.value]?.value,
                label: selectedValues?.[item.value]?.value,
              }
            : null,
        };
      }
      return {
        ...initialValues,
        [item.value]:
          (selectedValues !== undefined && selectedValues[item.value]?.value) ||
          selectedValues[item.value]
            ? selectedValues[item.value]?.value || selectedValues[item.value]
            : formValues?.[item.label] || '',
      };
    }, {});

    return { ...currentFormListValue, ...subSelectListValue };
  };

  const getFormValidation = () => {
    if (!getCurrentFormList?.formFields) {
      return {};
    }
    return getCurrentFormList.formFields.reduce((validation, item) => {
      if (item.fieldName.includes('name')) {
        return {
          ...validation,
          [item.fieldName]: showICPForm.clone
            ? Yup.string()
                .test(
                  'copy-of',
                  'Please update your ICP name. ‘Copy of’ is not valid.',
                  (value) => !value?.includes('Copy of'),
                )
                .required(`${item.label} is required`)
            : Yup.string().required(`${item.label} is required`),
        };
      }
      if (item.fieldName.includes('profileType')) {
        return {
          ...validation,
          [item.fieldName]: Yup.object()
            .nullable()
            .required('Please select type.')
            .test('is-valid-object', 'Please select type.', (value) => {
              if (!value) return false;
              return !!value?.value?.trim();
            }),
        };
      }
      if (item.fieldName.includes('jobTitle')) {
        return {
          ...validation,
          [item.fieldName]: Yup.object()
            .nullable()
            .required('Please select job title.')
            .test('is-valid-object', 'Please select job title.', (value) => {
              if (!value) return false;
              return Array.isArray(value.value) && !!value?.value?.length;
            }),
        };
      }
      if (item.fieldName.includes('industry')) {
        return {
          ...validation,
          [item.fieldName]: Yup.object()
            .nullable()
            .required('Please select industry.')
            .test('is-valid-object', 'Please select industry.', (value) => {
              if (!value) return false;
              return Array.isArray(value.value) && !!value?.value?.length;
            }),
        };
      }
      if (item.fieldName.includes('contactLocation')) {
        return {
          ...validation,
          [item.fieldName]: Yup.object()
            .nullable()
            .required('Location is required.')
            .test('is-valid-object', 'Location is required.', (value) => {
              if (!value) return false;
              return Array.isArray(value.value) && !!value?.value?.length;
            }),
        };
      }
      return validation;
    }, {});
  };

  const handleSubmitICP = async (values) => {
    setLoading(true);
    const payload = {
      ...selectedValues,
      name: values?.name,
      jobTitle: values?.jobTitle,
      profileType: values?.profileType.value,
      industry: values?.industry,
      contactLocation: values?.contactLocation,
      painPoints: values?.painPoints,
      otherInfo: values?.otherInfo,
    };
    const cleanedData = removeNullValues(editICPValue);
    const cleanedPayload = removeNullValues(payload);
    Object.keys(cleanedData).forEach((key) => {
      if (!(key in cleanedPayload)) {
        payload[key] = null;
      }
    });

    try {
      if (showICPForm.edit) {
        payload.id = showICPForm.id;
        await updateICP(payload);
      } else {
        await saveICP(payload);
      }
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
      setLoading(false);
      setShowICPForm({ isOpen: false, edit: false, clone: false, id: null });
      let toastMessage = '';
      if (showICPForm.edit) {
        toastMessage = 'ICP edited successfully';
      } else if (showICPForm.clone) {
        toastMessage = 'ICP cloned successfully';
      } else {
        toastMessage = 'ICP saved successfully';
      }
      toast.success(toastMessage);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAddAutocomplete = () => {
    setAutocompleteList((prevList) => [...prevList, { id: Date.now(), value: '' }]);
  };

  const handleAutocompleteChange = (event, newValue, id) => {
    const findTypeData = updateOptionList.find((v) => v?.value === event);
    if (!findTypeData) return;
    const shouldRemoveOptions = removeOptionListCheck.includes(findTypeData.value);
    const options = shouldRemoveOptions
      ? []
      : Array.isArray(findTypeData?.option)
      ? findTypeData.option.map((opt) => ({ label: opt, value: opt }))
      : typeof findTypeData?.option === 'string'
      ? [{ label: findTypeData.option, value: findTypeData.option }]
      : [];

    const newItemData = {
      ...findTypeData,
      option: shouldRemoveOptions ? [] : findTypeData.option,
      options,
    };

    const tempAutocompleteList = autocompleteList?.map((item) =>
      item.id === id ? { ...item, ...newItemData } : item,
    );

    setAutocompleteList(tempAutocompleteList);

    setSubSelectList((prevList) => {
      const filteredList = prevList.filter((item) =>
        tempAutocompleteList.some((tempItem) => tempItem.id === item.id),
      );

      const itemExists = filteredList.some((item) => item.id === id);
      return itemExists
        ? filteredList.map((item) => (item.id === id ? { ...newItemData, id } : item))
        : [...filteredList, { id, ...newItemData }];
    });
  };

  const handleAutocomplete = (event, newValue, itemId) => {
    const isMultiple = Array.isArray(newValue);
    const selectedValue = isMultiple
      ? { value: newValue, type: 'multiple' }
      : { value: newValue?.value, type: 'single' };

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [itemId]: selectedValue,
    }));
  };

  const handleDeleteAutocomplete = (deleteItem) => {
    setAutocompleteList((prevList) => prevList.filter((item) => item.id !== deleteItem?.id));
    setSubSelectList((prevList) => prevList.filter((item) => item.id !== deleteItem?.id));
    if (selectedValues.hasOwnProperty(deleteItem?.value)) {
      const selectedValuesData = selectedValues;
      delete selectedValuesData[deleteItem?.value];
      setSelectedValues(selectedValuesData);
    }
  };

  const getOptionList = (fieldName) => {
    if (removeOptionListCheck.includes(fieldName)) return;
    const findOptionField = updateOptionList?.find((ele) => ele.value === fieldName);
    return findOptionField?.option?.map((item) => ({
      label: item,
      value: item,
    }));
  };

  const getRemoteMethod = (fieldName) => {
    switch (fieldName) {
      case 'jobTitle':
        return fetchJobList;
      case 'technologyUsed':
        return fetchTechUsed;
    }
  };

  const renderField = (
    item,
    optionList,
    handleDeleteAutocomplete,
    showDeleteOption,
    setShowDeleteOption,
    errors,
    setFieldValue,
  ) => {
    if (item.type === 'multiple' || item.type === 'single') {
      const optionList = Array.isArray(item?.options) ? item?.options : [];

      const defaultValueSingle =
        selectedValues?.[item.value]?.type === 'single'
          ? {
              value: selectedValues?.[item.value]?.value,
              label: selectedValues?.[item.value]?.value,
            }
          : null;
      const defaultValueMultiple =
        selectedValues && Array.isArray(selectedValues[item.value]?.value)
          ? selectedValues[item.value].value.map((val) => ({
              label: val?.label || val,
              value: val?.value || val?.label || val,
            }))
          : [];
      const defaultValue = item.type === 'single' ? defaultValueSingle : defaultValueMultiple;
      const remoteMethod = getRemoteMethod(item?.fieldName || item?.value);

      return (
        <Stack
          direction="row"
          spacing={1}
          key={item.value}
          width={'100%'}
          onClick={() => setShowDeleteOption(null)}
          onMouseEnter={() => setShowDeleteOption(item.id)}
          onMouseLeave={() => setShowDeleteOption(null)}
        >
          <AutocompleteField
            remoteMethod={
              removeOptionListCheck.includes(item?.fieldName || item?.value) ? remoteMethod : null
            }
            showAddNew={removeOptionListCheck.includes(item?.fieldName || item?.value)}
            isDynamicFiled
            item={item}
            errors={errors}
            defaultValue={defaultValue}
            setFieldValue={setFieldValue}
            optionList={
              removeOptionListCheck.includes(item?.fieldName || item?.value) ? [] : optionList
            }
            handleAutocomplete={handleAutocomplete}
          />
        </Stack>
      );
    }
    if (item.type === 'string') {
      return (
        <Stack
          direction="row"
          spacing={1}
          key={item.id}
          width={'100%'}
          onClick={() => setShowDeleteOption(null)}
          onMouseEnter={() => setShowDeleteOption(item.id)}
          onMouseLeave={() => setShowDeleteOption(null)}
        >
          <Form.Field.Input
            fullWidth
            variant="outlined"
            name={item.value}
            // defaultValue={selectedValues[item.value]}
            placeholder={item?.placeholder}
            className={classes.inputFieldContainer}
            style={{ marginBottom: '0px' }}
            size="small"
            isSmall
            hideHelperText={true}
            onChange={(inputValue) => {
              setSelectedValues((prevValues) => ({
                ...prevValues,
                [item.value]: inputValue,
              }));
            }}
          />
        </Stack>
      );
    }
  };

  return (
    <Modal
      open={showICPForm.isOpen}
      title={<ModalTitle getModalTitle={getModalTitle} />}
      className={classes.modalContainer}
      titleStyle={classes.modalTitle}
      closeIconClass={classes.modalCloseIcon}
      onClose={() => setShowICPForm({ isOpen: false, edit: false, clone: false, id: null })}
    >
      <Box className={classes.modalContent}>
        {loadingPage ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress size={30} color="secondary" />
          </Box>
        ) : (
          <Form
            initialValues={getInitialValues()}
            validationSchema={Yup.object().shape(getFormValidation())}
            enableReinitialize={true}
            onSubmit={handleSubmitICP}
          >
            {({
              values,
              setFieldValue,
              setValues,
              errors,
              touched,
              setFieldTouched,
              ...formProps
            }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formProps.submitForm();
                    return false;
                  }}
                  id="icp-form"
                  className={classes.formStyle}
                  noValidate
                >
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    height="100%"
                    paddingY="16px"
                    paddingX="24px"
                  >
                    <Box width="100%">
                      <Grid container sx={{ rowGap: '16px' }}>
                        {getCurrentFormList?.formFields.map((item, index) => {
                          const isShowError =
                            (formProps.submitCount || touched[item.fieldName]) &&
                            errors[item.fieldName];
                          const errorMessage = isShowError ? errors : {};
                          if (item.fieldName === 'contactLocation') {
                            const defaultValue =
                              values &&
                              values[item.fieldName] &&
                              !Array.isArray(values[item.fieldName])
                                ? values[item.fieldName]
                                : null;

                            return (
                              <Grid item xs={12} container alignItems="center">
                                <Grid item xs={5} container alignItems="center">
                                  <FieldIconLabel item={item} errors={errorMessage} />
                                </Grid>
                                <Grid item xs={7}>
                                  <Box
                                    sx={{
                                      '& .MuiFormControl-root': { margin: '0px !important' },
                                    }}
                                  >
                                    <LocationAutocomplete
                                      classes={classes.selectFieldContainer}
                                      name={item.fieldName}
                                      item={item}
                                      setFieldValue={setFieldValue}
                                      errors={errorMessage}
                                      defaultValue={defaultValue}
                                      setFieldTouched={setFieldTouched}
                                    />
                                  </Box>
                                </Grid>
                                {isShowError && <FieldError error={errors[item.fieldName]} />}
                              </Grid>
                            );
                          }
                          if (item.fieldType === 'text') {
                            return (
                              <Grid item xs={12} container alignItems="center">
                                <Grid item xs={5} container alignItems="center">
                                  <FieldIconLabel item={item} errors={errorMessage} />
                                </Grid>
                                <Grid item xs={7}>
                                  <Box
                                    sx={{
                                      '& .MuiFormControl-root': { margin: '0px !important' },
                                    }}
                                  >
                                    <Form.Field.Input
                                      fullWidth
                                      variant="outlined"
                                      name={item.fieldName}
                                      placeholder={
                                        item?.placeholder || item.fieldName === 'jobTitle'
                                          ? 'Enter jobTitle'
                                          : ''
                                      }
                                      className={classes.inputFieldContainer}
                                      style={{ marginBottom: '0px' }}
                                      size="small"
                                      isSmall
                                      hideHelperText={true}
                                      errorBorder={isShowError}
                                    />
                                  </Box>
                                </Grid>
                                {isShowError && <FieldError error={errors[item.fieldName]} />}
                              </Grid>
                            );
                          }
                          if (item.fieldType === 'lookup') {
                            const optionList = getOptionList(item.fieldName);
                            const remoteMethod = getRemoteMethod(item.fieldName);

                            const defaultValueMultiple =
                              values &&
                              values[item.fieldName] &&
                              Array.isArray(values[item.fieldName]?.value)
                                ? values[item.fieldName]?.value?.map((val) => ({
                                    label: val,
                                    value: val,
                                  }))
                                : [];

                            const defaultValueSingle =
                              values && values[item.fieldName]?.value
                                ? {
                                    value: values?.[item.fieldName]?.value,
                                    label: values?.[item.fieldName]?.label
                                      ? values?.[item.fieldName]?.label
                                      : values?.[item.fieldName]?.value,
                                  }
                                : {};

                            const defaultValue =
                              item.lookupType === 'single'
                                ? defaultValueSingle
                                : defaultValueMultiple;

                            const getRenderIcon = iconList.find(
                              (v) => v?.value === values?.[item.fieldName]?.value,
                            );

                            return (
                              <Grid item xs={12} container alignItems="center">
                                <Grid item xs={5} container alignItems="center">
                                  <FieldIconLabel item={item} errors={errorMessage} />
                                </Grid>
                                <Grid item xs={7}>
                                  <Box
                                    sx={{
                                      '& .MuiFormControl-root': { margin: '0px !important' },
                                    }}
                                  >
                                    <AutocompleteField
                                      showAddNew={item.fieldName === 'jobTitle'}
                                      saveOnEnter={item.fieldName === 'jobTitle'}
                                      remoteMethod={
                                        removeOptionListCheck.includes(item.fieldName)
                                          ? remoteMethod
                                          : null
                                      }
                                      hideInputSearchOnOpen={item.fieldName === 'jobTitle'}
                                      freeSolo={item.fieldName === 'jobTitle'}
                                      item={item}
                                      errors={errorMessage}
                                      defaultValue={defaultValue}
                                      setFieldValue={setFieldValue}
                                      setFieldTouched={setFieldTouched}
                                      optionList={
                                        removeOptionListCheck.includes(item.fieldName)
                                          ? []
                                          : optionList
                                      }
                                      getRenderIcon={getRenderIcon}
                                      handleAutocomplete={handleAutocomplete}
                                    />
                                  </Box>
                                </Grid>
                                {isShowError && <FieldError error={errors[item.fieldName]} />}
                              </Grid>
                            );
                          }
                        })}
                        {!!autocompleteList?.length &&
                          autocompleteList
                            .sort((a, b) => (b.value ? 1 : 0) - (a.value ? 1 : 0))
                            .map((item) => {
                              const optionList = filterExistFieldList?.map((ele, inx) => {
                                const isExist = subSelectList.some(
                                  (item) => item.value === ele.value,
                                );
                                if (isExist) {
                                  return {
                                    ...ele,
                                    disabled: true,
                                  };
                                }
                                return ele;
                              });
                              return (
                                <Grid item xs={12} container alignItems="center" rowGap="16px">
                                  <Grid item xs={5} container alignItems="center" rowGap="16px">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      key={item.id}
                                      sx={{
                                        width: '100%',
                                        '& .MuiFormControl-root': { margin: '0px !important' },
                                      }}
                                      onClick={() => setShowDeleteOption(null)}
                                      onMouseEnter={() => setShowDeleteOption(item.id)}
                                      onMouseLeave={() => setShowDeleteOption(null)}
                                    >
                                      <InputSelect
                                        showNone={false}
                                        options={optionList}
                                        value={item.value}
                                        placeholder={item?.placeholder || 'Select'}
                                        fullWidth
                                        onChange={(event, newValue) => {
                                          handleAutocompleteChange(event, newValue, item.id);
                                          setValues({ ...values, event: event });
                                        }}
                                        withElpsis
                                        sx={{
                                          backgroundColor: '#F9F9F9',
                                          '& .MuiSelect-nativeInput': {
                                            backgroundColor: '#F9F9F9',
                                          },
                                          '& .MuiListItemIcon-root': {
                                            minWidth: '28px !important',
                                          },
                                          '& .MuiSelect-select': {
                                            padding: '10px 16px !important',
                                          },
                                          '& .MuiTypography-root': {
                                            fontSize: '16px !important',
                                            fontWeight: '500 !important',
                                            color: '#000000DE !important',
                                          },
                                          '& .MuiSelect-nativeInput::placeholder': {
                                            fontSize: '16px !important',
                                            fontWeight: '500 !important',
                                            color: '#00000099 !important',
                                          },
                                        }}
                                        size="small"
                                        variant="outlined"
                                        optLabel="label"
                                        isSmall
                                        optValue="value"
                                      />
                                      <img
                                        src={images.icp.deleteIcon}
                                        alt=""
                                        width={'24px'}
                                        height={'24px'}
                                        style={{
                                          margin: '0px 8px',
                                          cursor: 'pointer',
                                          visibility:
                                            showDeleteOption === item.id ? 'visible' : 'hidden',
                                        }}
                                        onClick={() => handleDeleteAutocomplete(item)}
                                      />{' '}
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={7} container alignItems="center" rowGap="16px">
                                    {renderField(
                                      item,
                                      optionList,
                                      handleDeleteAutocomplete,
                                      showDeleteOption,
                                      setShowDeleteOption,
                                      errors,
                                      setFieldValue,
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}
                        <Box display="flex" justifyContent="center" width="100%">
                          <Button
                            variant="contained"
                            onClick={handleAddAutocomplete}
                            className={classes.addButtonContainer}
                            // disabled={allCriteriaAdded()}
                          >
                            <Stack direction={'row'} gap="8px" alignItems={'center'}>
                              <AddCircleOutlineIcon sx={{ color: '#1976D2 !important' }} />{' '}
                              <Typography variant="body2">Add additional criteria </Typography>
                            </Stack>
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    padding="24px"
                    display={'flex'}
                    gap="16px"
                    justifyContent={'end'}
                    sx={{ borderTop: '1px solid #0000001f' }}
                  >
                    <Button
                      className={classes.cancelButtonContainer}
                      color={'secondary'}
                      onClick={() =>
                        setShowICPForm({ isOpen: false, edit: false, clone: false, id: null })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={loading}
                      className={classes.buttonContainer}
                      disabled={!Object.keys(values).length}
                    >
                      <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Form>
        )}
      </Box>
    </Modal>
  );
};

export default createICPContainer;
