import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Grid, Box, InputAdornment } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import images from 'src/config/images';
import { linkedinRegex } from 'src/config';
import { getPersonaTypes, getCompanyTypes } from 'src/modules/auth/api/aiServicesApi';
import moment from 'moment';

function DetailsForm({
  profileImage,
  status,
  editUser,
  userImg,
  deleteProfileImage,
  formData,
  hasLmsAccess,
  hasOutreachAccess,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const [personaTypes, setPersonaTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);

  useEffect(() => {
    getPersonaTypes().then((res) => {
      setPersonaTypes(res.taxonomies);
    });
    getCompanyTypes().then((res) => {
      setCompanyTypes(res.taxonomies);
    });
  }, []);

  const titleList = useMemo(() => {
    if (props.globals?.titlesList && props.globals?.titlesList.length) {
      return props.globals?.titlesList.map((i, key) => ({ id: i.value, value: i.label }));
    }
    return [];
  }, [props.globals]);

  useEffect(() => {
    if (editUser && editUser?.id) {
      setCurrentUser(editUser);
    }
  }, [editUser]);

  useEffect(() => {
    if (selectedImage != null) {
      const formData = new FormData();
      formData.append('file', selectedImage, selectedImage?.name);
      props.uploadImage(formData);
    }
  }, [selectedImage]);

  const getFilteredPersonaTypes = (value) => {
    if (!value || value == '') return [];
    const companyType = companyTypes.find((type) => type.id === value);
    if (!companyType) {
      return [];
    }
    if (companyType.name.toLowerCase().includes('recruitment')) {
      return personaTypes.filter((type) => ['Candidate', 'Client'].includes(type.name));
    } else if (companyType.name.toLowerCase().includes('sales')) {
      return personaTypes.filter((type) => type.name === 'Client');
    } else {
      return [];
    }
  };

  const handleSubmit = async (values, form) => {
    setLoading(true);
    const data = { ...values };
    data.title = data.title.value;
    data.startDate = data.startDate._d;
    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      await props.putUser(updatedRow);
      drawerToggle({ edit: null, open: false });
    }
    setLoading(false);
  };

  return (
    <Form
      initialValues={{
        title: currentUser?.title ? { id: currentUser?.title, value: currentUser?.title } : {},
        fname: currentUser.fname || '',
        lname: currentUser.lname || '',
        email: currentUser.email || '',
        jobTitle: currentUser.jobTitle || '',
        reciveEmailId: currentUser.reciveEmailId || '',
        linkedin: currentUser.linkedin || '',
        mobile: currentUser.mobile || '',
        officeNo: currentUser.officeNo || '',
        xing: currentUser.xing || '',
        calendar: currentUser.calendar || '',
        userImage: currentUser?.userImage || '',
        startDate:
          currentUser?.dateFormat && currentUser?.startDate
            ? moment(currentUser?.startDate)
            : moment(),
        companyType: currentUser?.companyType || '',
        personaType: currentUser?.personaType || '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.object().required('Please choose a Assigned title to continue.').nullable(true),
        fname: Yup.string().required('Please enter First Name!'),
        lname: Yup.string().required('Please enter Last Name!'),
        email: Yup.string()
          .required('Please enter email!')
          .test({
            message: () => 'Email already exists',
            test: async (values) => {
              if (editUser?.id) {
                return true;
              } else {
                try {
                  let response = await api(`/user?email=${values}`, null, 'get');
                  if (response.users.length > 0) {
                    return false;
                  } else {
                    return true;
                  }
                } catch (error) {}
              }
            },
          }),
        jobTitle: Yup.string(),
        linkedin: Yup.string().matches(linkedinRegex, 'Enter valid linkedin profile url!'),
        companyType: Yup.string().required('Please choose a company type!'),
        personaType: Yup.string().required('Please choose a persona type!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        let titleLabel = props.values.title.value ? 'Title' : '';
        const companyType = props.values.companyType;
        const filteredPersonas = getFilteredPersonaTypes(companyType);

        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Form.Field.Avatar
                    name="userImage"
                    crop={true}
                    alt={`${currentUser.fname} ${currentUser.lname}`}
                    cropShape="round"
                    showEditButton={true}
                    editButtonTitle="Upload"
                    editButtonProps={{
                      variant: 'outlined',
                      color: 'secondary',
                    }}
                  />
                  <Button iconButton onClick={deleteProfileImage}>
                    <DeleteIcon />
                  </Button>
                </Stack>

                <Typography variant="h4" pt={2} gutterBottom color="textSecondary">
                  User details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      showAvatar={false}
                      clearOnEscape
                      options={titleList}
                      variant="outlined"
                      name="title"
                      label={titleLabel}
                      placeholder="Title"
                      optLabel="value"
                      optValue="id"
                      allowAdd={true}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="fname"
                      label="First name"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="lname"
                      label="Last name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="jobTitle"
                      label="Job title"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Field.Select
                      fullWidth
                      variant="outlined"
                      name="companyType"
                      label="Company type"
                      options={companyTypes}
                      optLabel="name"
                      optValue="id"
                      showNone={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Field.Select
                      fullWidth
                      variant="outlined"
                      name="personaType"
                      label="Persona type"
                      optLabel="name"
                      optValue="id"
                      options={filteredPersonas}
                      showNone={false}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h4" pt={2} gutterBottom color="textSecondary">
                  Contact details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={hasOutreachAccess === true ? 6 : 12} mt={0.7} mb={-2}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="email"
                      label="Email"
                    />
                  </Grid>

                  {hasOutreachAccess === true ? (
                    <>
                      <Grid item xs={6}>
                        <Form.Field.Phone
                          fullWidth
                          variant="outlined"
                          name="mobile"
                          label="Mobile number"
                        />
                      </Grid>

                      <Grid item xs={6} mt={-1.4}>
                        <Form.Field.Phone
                          fullWidth
                          rows={4}
                          variant="outlined"
                          name="officeNo"
                          label="Office number"
                        />
                      </Grid>
                      <Grid item xs={6} mt={-1}>
                        <Form.Field.Input
                          fullWidth
                          variant="outlined"
                          name="calendar"
                          label="Calendar link"
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                {hasOutreachAccess === true ? (
                  <>
                    <Spacer x={2} y={2} />
                    <Typography variant="h4" gutterBottom color="textSecondary">
                      Social links
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Form.Field.Input
                          fullWidth
                          rows={4}
                          variant="outlined"
                          name="linkedin"
                          label="Linkedin"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LinkedInIcon style={{ fill: '#1282B2', width: 28, height: 28 }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Form.Field.Input
                          fullWidth
                          rows={4}
                          variant="outlined"
                          name="xing"
                          label="Xing"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={images.providers.xing}
                                  style={{ width: 24, height: 24 }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Box>

              <Box
                mt={2}
                sx={{
                  textAlign: 'right',
                }}
              >
                <Button variant="contained" color="secondary" type="submit" loading={loading}>
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default DetailsForm;
