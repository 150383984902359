import React from 'react';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import {
  AIVoiceActionContainer,
  AIVoiceContentHeader,
  AIVoiceCreateViewHeader,
  AIVoiceCreateViewSubHeader,
  DetailedContent,
  HeadLineContent,
  StyledButton,
  useStyles,
} from '../../components/Voice/styles';
import images from 'src/config/images';
import { Button, Form } from '../../../../components/shared';

const CreateVoiceDefaultView = ({ voiceData }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }}>
        <Stack
          direction={'row'}
          gap={'16px'}
          sx={{ padding: '24px', borderBottom: `1px solid ${theme.palette.border.default}` }}
        >
          <img src={images.voice.toneOfVoiceIcon} alt={''} height={32} width={32} />
          <Stack gap={'8px'}>
            <AIVoiceCreateViewHeader>
              AI: {voiceData?.id ? 'Edit' : 'Create'} a Tone of Voice
            </AIVoiceCreateViewHeader>
            <AIVoiceCreateViewSubHeader isGrey>
              Set up a tone of voice profile by analysing example copy to make your generated
              content sound just like you!
            </AIVoiceCreateViewSubHeader>
          </Stack>
        </Stack>
        <Stack gap={'24px'} sx={{ padding: '24px 72px' }}>
          <Stack
            gap={'24px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <AIVoiceCreateViewHeader>
              Based on the example copy, here is your tone of voice!
            </AIVoiceCreateViewHeader>
            <Button
              startIcon={<img src={images.voice.reanalyseIcon} alt={''} />}
              variant={'text'}
              className={classes.reAnalyseButton}
            >
              Re-analyse
            </Button>
          </Stack>
          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} md={7}>
              <Stack gap={'24px'}>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Headline</AIVoiceContentHeader>
                  <HeadLineContent className={classes.gradiantBG}>
                    Conversational yet authoritative, helpful and insightful.
                  </HeadLineContent>
                </Stack>
                <Grid container columnSpacing={'24px'}>
                  <Grid item xs={12}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Style</AIVoiceContentHeader>
                      <Stack flexDirection="row" gap={1}>
                        <Button
                          variant="contained"
                          color="gradient"
                          sx={{ cursor: 'auto' }}
                          className={classes.gradientButtonAICreate}
                        >
                          Neutral
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Tone</AIVoiceContentHeader>
                      <Stack direction="row" gap={1}>
                        <Stack flexDirection="row" gap={1}>
                          <Button
                            variant="contained"
                            color="gradient"
                            sx={{ cursor: 'auto' }}
                            className={classes.gradientButtonAICreate}
                          >
                            Encouraging
                          </Button>
                          <Button
                            variant="contained"
                            color="gradient"
                            sx={{ cursor: 'auto' }}
                            className={classes.gradientButtonAICreate}
                          >
                            Prefessional
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Pacing</AIVoiceContentHeader>
                  <HeadLineContent isGrey>Short and snappy</HeadLineContent>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap={'24px'} height="100%">
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Your Tone of Voice in Action</AIVoiceContentHeader>
                  <AIVoiceCreateViewSubHeader isGrey>
                    Apply your tone of voice to some sample text
                  </AIVoiceCreateViewSubHeader>
                  <Stack>
                    <Form.Field.Select
                      value="Client cold outreach"
                      placeholder={'Select a sample text'}
                      fullWidth
                      withElpsis
                      className={classes.inputSelectSampleText}
                      sx={{
                        mt: 0,
                        mb: 0,
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      }}
                      size="small"
                      variant="outlined"
                      optLabel="label"
                      isSmall
                      optValue="value"
                      showNone={false}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                        disabled: true,
                      }}
                    />
                  </Stack>
                </Stack>
                <AIVoiceActionContainer>
                  <DetailedContent isGrey>
                    In cold emailing, each message is a door opener, not a sales pitch. Focus on who
                    your ideal customer is and speak directly to their needs.
                  </DetailedContent>
                  <DetailedContent isGrey>
                    Remember, effective cold emails aren’t about pushing for the sale but about
                    sparking a conversation and building rapport. We’ve gathered data-backed
                    insights that can guide you in the right direction—because, after all, the more
                    informed your outreach, the better your results.
                  </DetailedContent>
                </AIVoiceActionContainer>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box padding="24px" display={'flex'} gap="16px" justifyContent={'end'}>
          <Button className={classes.textButton} color={'secondary'} onClick={() => null}>
            Back to input screen
          </Button>
          <Button variant={'contained'} color="secondary" className={classes.secondaryButton}>
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};
export default CreateVoiceDefaultView;
