export const parseHTMLFromObject = (content, fields = []) => {
  const htmlString = fields
    .filter((section) => content[section])
    .map((section) => {
      return content[section]?.trim('')?.replaceAll('<p>', '<p style="margin-block: 16px">');
    })
    .join('');

  return htmlString ?? '';
};
