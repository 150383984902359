import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Stack, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  AIDetailedContent,
  AIVoiceActionContainer,
  AIVoiceContentHeader,
  AIVoiceCreateViewHeader,
  AIVoiceCreateViewSubHeader,
  DetailedContent,
  HeadLineContent,
  StyledButton,
  useStyles,
} from '../../components/Voice/styles';
import images from 'src/config/images';
import { Button, Form } from '../../../../components/shared';
import { formatKey, handleSocketListener } from '../../../../utils/helper';
import { useSocketStore } from '../../../../components/hooks/socketConnector';
import { Title } from '../../../../components/App/Notifications/styles';
import AnalyseCopyModal from '../../components/Voice/VoiceDialog/AnalyseCopyModal';

const AICreateVoice = ({
  formProps,
  setOpenAICreateVoice,
  setShowLoading,
  setShowVoiceNameField,
  maxHeight,
  manageAiGeneration,
  voiceData,
  sampleTextList,
}) => {
  const [loading, setLoading] = useState(false);
  const [openPromptView, setOpenPromptView] = useState(false);
  const { emit, socket } = useSocketStore();

  const { values, errors } = formProps;
  const theme = useTheme();
  const classes = useStyles();

  const { Style, Tone, Tone_of_Voice = '', Pacing = '' } = values?.voicePrompt?.tovGuidelines || {};

  const styles = Style?.split(', ') || [];
  const tones = Tone?.split(', ') || [];

  const reAnalyse = () => {
    setShowLoading(true);
    setOpenAICreateVoice((prevState) => ({ ...prevState, reAnalyse: true }));
    formProps.setFieldValue('voicePrompt', {});
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'tov-v2',
        payload: {
          text: formProps?.values?.sampleText,
        },
      }),
    );

    const responseHandler = (res) => {
      const { Style, Tone } = res?.json?.tovGuidelines || {};
      formProps.setFieldValue('voicePrompt', res?.json);
      formProps.setFieldValue('style', Style);
      formProps.setFieldValue('tone', Tone?.split(', ') || []);
      setShowLoading(false);
    };

    const errorHandler = () => {
      setShowLoading(false);
    };

    handleSocketListener({
      socket,
      responseHandler,
      errorHandler,
    });
  };

  const onChangeSampleVoice = (value) => {
    setLoading(true);
    formProps.setFieldValue('sampleVoice', {
      key: value,
      label: value,
      voice: {},
    });
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'getemail',
        payload: JSON.stringify({ style: Style, tone: Tone, pacing: Pacing, purpose: value }),
      }),
    );
    const responseHandler = (res) => {
      formProps.setFieldValue('sampleVoice', {
        key: value,
        label: value,
        voice: res?.json,
      });
      setLoading(false);
    };
    const errorHandler = () => {
      setLoading(false);
    };

    handleSocketListener({
      socket,
      responseHandler,
      errorHandler,
    });
  };

  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }}>
        <Stack
          direction={'row'}
          gap={'16px'}
          sx={{ padding: '24px', borderBottom: `1px solid ${theme.palette.border.default}` }}
          className={'ai-create-voice-header'}
        >
          <img src={images.voice.toneOfVoiceIcon} alt={''} height={32} width={32} />
          <Stack gap={'8px'}>
            <AIVoiceCreateViewHeader>
              AI: {voiceData?.id ? 'Edit' : 'Create'} a Tone of Voice
            </AIVoiceCreateViewHeader>
            <AIVoiceCreateViewSubHeader isGrey>
              Set up a tone of voice profile by analysing example copy to make your generated
              content sound just like you!
            </AIVoiceCreateViewSubHeader>
          </Stack>
        </Stack>
        <Stack className={classes.contentContainer} gap={'24px'} sx={{ maxHeight }}>
          <Stack
            gap={'24px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <AIVoiceCreateViewHeader>
              Based on the example copy, here is your tone of voice!
            </AIVoiceCreateViewHeader>
            <Button
              startIcon={<img src={images.voice.reanalyseIcon} alt={''} />}
              variant={'text'}
              className={classes.reAnalyseButton}
              onClick={reAnalyse}
            >
              Re-analyse
            </Button>
          </Stack>
          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} md={7}>
              <Stack gap={'24px'}>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Overview</AIVoiceContentHeader>
                  <HeadLineContent isGrey>{Tone_of_Voice}</HeadLineContent>
                </Stack>
                {styles?.length > 0 && (
                  <Grid container columnSpacing={'24px'}>
                    <Grid item xs={12}>
                      <Stack gap={'8px'}>
                        <AIVoiceContentHeader>Style</AIVoiceContentHeader>
                        <Stack flexDirection="row" gap={1}>
                          {styles.map((style, index) => (
                            <Button
                              key={index}
                              variant="contained"
                              color="gradient"
                              sx={{ cursor: 'auto' }}
                              className={classes.gradientButtonAICreate}
                            >
                              {`${style?.charAt(0)?.toUpperCase() + style?.slice(1)}`}
                            </Button>
                          ))}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                )}
                {tones?.length > 0 && (
                  <Grid container columnSpacing={'24px'}>
                    <Grid item xs={12}>
                      <Stack gap={'8px'}>
                        <AIVoiceContentHeader>Tone</AIVoiceContentHeader>
                        <Stack flexDirection="row" gap={1}>
                          {tones.map((tone, index) => (
                            <Button
                              key={index}
                              variant="contained"
                              color="gradient"
                              sx={{ cursor: 'auto' }}
                              className={classes.gradientButtonAICreate}
                            >
                              {`${tone?.charAt(0)?.toUpperCase() + tone?.slice(1)}`}
                            </Button>
                          ))}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                )}
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Pacing</AIVoiceContentHeader>
                  <HeadLineContent isGrey>{Pacing}</HeadLineContent>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap={'24px'} height="100%">
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Your Tone of Voice in Action</AIVoiceContentHeader>
                  <AIVoiceCreateViewSubHeader isGrey>
                    Apply your tone of voice to some sample text
                  </AIVoiceCreateViewSubHeader>
                  <Stack>
                    <Form.Field.Select
                      options={[...sampleTextList]}
                      value={values?.sampleVoice?.key}
                      placeholder={'Select a sample text'}
                      fullWidth
                      withElpsis
                      className={classes.inputSelectSampleText}
                      sx={{
                        mt: 0,
                        mb: 0,
                        '.MuiOutlinedInput-notchedOutline': !errors.sampleTextType
                          ? { border: 0 }
                          : { borderRadius: '8px' },
                      }}
                      onChange={onChangeSampleVoice}
                      size="small"
                      variant="outlined"
                      optLabel="label"
                      isSmall
                      optValue="value"
                      showNone={false}
                      name="purpose"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    />
                  </Stack>
                </Stack>
                <AIVoiceActionContainer>
                  {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <CircularProgress size={30} color="secondary" />
                    </Box>
                  ) : (
                    <>
                      <DetailedContent isGrey>
                        {values?.sampleVoice?.voice?.Subject
                          ? values?.sampleVoice?.voice?.Subject
                          : ''}
                      </DetailedContent>
                      <DetailedContent isGrey>
                        {values?.sampleVoice?.voice?.Body ? (
                          <Title
                            dangerouslySetInnerHTML={{
                              __html: values?.sampleVoice?.voice?.Body?.replace(/\n/g, '<br />'),
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </DetailedContent>
                    </>
                  )}
                </AIVoiceActionContainer>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box
          padding="24px"
          display={'flex'}
          gap="16px"
          justifyContent={'end'}
          className={'ai-creation-footer'}
        >
          <Button
            className={classes.textButton}
            color={'secondary'}
            onClick={() => {
              formProps.setFieldValue('sampleText', values.sampleText);
              setOpenPromptView(true);
            }}
          >
            Back to input screen
          </Button>
          <Button
            variant={'contained'}
            color="secondary"
            className={classes.secondaryButton}
            onClick={() => {
              setShowVoiceNameField(true);
            }}
          >
            Save
          </Button>
        </Box>
      </Stack>
      <AnalyseCopyModal
        onCancel={() => setOpenPromptView(false)}
        onSubmit={() => {
          formProps.setTouched({
            sampleText: true,
          });
          formProps.validateForm().then((errors) => {
            if (errors && !errors?.sampleText) {
              formProps.setErrors({});
              manageAiGeneration(formProps);
            }
          });
        }}
        formProps={formProps}
        open={openPromptView}
      />
    </>
  );
};
export default AICreateVoice;
