import axios from 'axios';

const aiServicesApi = axios.create({
  baseURL: process.env.REACT_APP_AISERVICE_API_URL + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const botApi = axios.create({
  baseURL: process.env.REACT_APP_BOTAPI_URL + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registerAiUser = async (data) => {
  return aiServicesApi.post('/user/register', data);
};

export const syncUser = async (data) => {
  return aiServicesApi.post('/user/sync', data);
};

export const getPersonaTypes = async () => {
  const data = await botApi.get(
    '/taxonomies?type=personaICP&_size=100&_from=0&isDeleted=false&enabled=true',
  );
  return data.data;
};

export const getCompanyTypes = async () => {
  const data = await botApi.get(
    '/taxonomies?type=companyType&_size=100&_from=0&isDeleted=false&enabled=true',
  );
  return data.data;
};
