import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Typography,
  CircularProgress,
  Backdrop,
  Stack,
  Select,
  Dialog,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'src/components/shared';

import { useStyles } from './styles';
import images from 'src/config/images';
import AIPromptView from './AIPromptView';
import { fetchBrandVoice, getCredit } from '../../../api/sequenceApi';
import toast from '../../../../../utils/toast';
import { useSocketStore } from '../../../../../components/hooks/socketConnector';
import { fetchAiPromptForm } from '../../../../app/api/appApis';
import EmailWriterModal from '../../Sequence/AiWriterModal';
import { isJson } from '../../../../../utils/helper';
import { parseHTMLFromObject } from 'src/utils/parseHtml';

const AIInsertButton = ({ classes, label, step, onClick, disabled }) => {
  return (
    <Button className={classes.aiInsertButton} disabled={disabled} onClick={onClick}>
      <img src={images.icons.checkIcon} /> {label}
    </Button>
  );
};

const AIWriterFormHeader = ({
  options,
  classes,
  step,
  showSelect,
  selectedEmailFormType,
  setSelectedEmailFormType,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ padding: '16px 24px' }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={step === 0 ? '24px' : '16px'}>
          {step === 1 ? (
            <img
              src={images.icons.backIcon}
              onClick={() => props?.setStep(0)}
              style={{ cursor: 'pointer' }}
              alt={''}
            />
          ) : null}
          <Typography className={classes.headerText} style={{ fontWeight: 500 }}>
            {step === 0 ? 'AI Writer' : 'Back to AI Prompt'}
          </Typography>
          {step === 0 && props?.formType === 'email' ? (
            <Select
              variant={'outlined'}
              fullWidth
              sx={{
                width: '204px',
                color: '#1976D2',
                fontWeight: 500,
                background: '#1976D214',
                '& .MuiOutlinedInput-input': {
                  padding: '6px 4px 6px 16px',
                  fontSize: '14px',
                },
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
              isSmall
              value={selectedEmailFormType}
              onChange={(e) => {
                setSelectedEmailFormType(e?.target?.value);
              }}
              options={options}
            >
              {options.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          ) : null}
        </Stack>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => props?.handleClose()} />
      </Stack>
    </Box>
  );
};

function getCurrentDateAndMonthEnd() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  const day = '01';

  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const endDay = String(lastDayOfMonth.getDate()).padStart(2, '0');

  const currentDate = `${year}-${month}-${day}`;
  const endOfMonthDate = `${year}-${month}-${endDay}`;

  return { currentDate, endOfMonthDate };
}

function AIWriteForm(props) {
  if (!props?.open) return <></>;
  const { emit, aiResponse: socketAiResponse, events, setAiResponse, socket } = useSocketStore();

  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiResponse, setAIResponse] = useState([]);
  const [aiPromptFormResponse, setAiPromptFormResponse] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(-1);
  const [selectedEmailFormType, setSelectedEmailFormType] = useState('');
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [aiPromptData, setAIPromptData] = useState({});
  const [credit, setCredit] = useState(0);

  const aiFormFieldsWithValue = useSelector((state) => state.app.globals?.aiFormFieldsWithValue);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchData = async () => {
      await fetchOption('');
      await fetchAiPromptFormData();
    };
    fetchData();
  }, [props?.open, props?.formType, props?.linkedinType]);

  useEffect(() => {
    (async () => {
      const { currentDate, endOfMonthDate } = getCurrentDateAndMonthEnd();
      const res = await getCredit(endOfMonthDate, currentDate, user?.id);
      const creditAmount = user?.beSpokeAllocation ? user.creditLimit : user.defaultCredit;
      const totalCredit = (+creditAmount - (res ? +res[0]?.spend : 0)).toFixed(2);
      setCredit(totalCredit);
    })();
  }, []);

  const isEmailForm = props?.formType === 'email';

  const linkedInFormFields = [
    {
      fieldName: 'type',
      label: 'Type',
      fieldType: 'radio',
      fieldOptions: ['Client', 'Candidate', 'Other'],
    },
    {
      fieldName: 'purpose',
      label: 'Purpose',
      fieldType: 'lookup',
      prompt: 'Please specify the purpose of the email.',
      fieldChildren: aiFormFieldsWithValue?.Purpose || [],
    },
    {
      fieldName: 'toneOfVoice',
      label: 'Tone of voice',
      fieldType: 'lookup',
    },
    {
      fieldName: 'furtherInstruction',
      label: 'Further Instructions',
      fieldType: 'textArea',
    },
  ];

  const fetchAiPromptFormData = async () => {
    setLoadingPage(true);
    try {
      const aiPromptFormData = await fetchAiPromptForm();
      if (aiPromptFormData.length) {
        setSelectedEmailFormType(aiPromptFormData[0]?.formName);
        setAiPromptFormResponse(aiPromptFormData);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const fetchOption = async (val) => {
    setLoadingPage(true);
    try {
      const query = val && val;
      const response = await fetchBrandVoice(query);
      setOptions(response);

      const defaultOption = response.find((option) => option.isDefault);
      if (defaultOption) {
        setSelectedValue(defaultOption);
      } else {
        const value = response.find((option) => option.name === val);
        setSelectedValue(value);
      }
      setLoadingPage(false);
    } catch (err) {
      console.error('Error fetching options:', err);
      setOptions([]);
      setSelectedValue(null);
      setLoadingPage(false);
    }
  };

  const fetchVoiceData = async (val) => {
    setLoadingPage(true);
    try {
      const query = val && val;
      const response = await fetchBrandVoice(query);
      setOptions(response);
      setLoadingPage(false);
    } catch (err) {
      setOptions([]);
      setSelectedValue(null);
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (events?.length > 0 && events[0]?.event === 'pipeline:execute:error') {
      setStep(0);
      setLoading(false);
    }
  }, [socketAiResponse, events]);

  const getWriteFromHeaderOptions = useMemo(() => {
    return aiPromptFormResponse.map((item) => item.formName);
  }, [aiPromptFormResponse]);

  const getCurrentForm = useMemo(() => {
    if (isEmailForm) {
      return aiPromptFormResponse.find((item) => item.formName === selectedEmailFormType);
    } else {
      return { formFields: linkedInFormFields };
    }
  }, [selectedEmailFormType, aiPromptFormResponse, isEmailForm]);

  useEffect(() => {
    getCurrentForm?.formFields.forEach((item) => {
      if (item.fieldName.includes('type')) {
        setFormValues({
          ...formValues,
          [item.fieldName]: item.fieldOptions[0],
        });
      }
    });
  }, [getCurrentForm]);

  const handleClose = () => {
    setStep(0);
    setSelectedOption(null);
    props?.setOpenAIWriter(false);
    setAIResponse(null);
    setAiResponse(null);
  };

  const aiGenerationType = {
    email: { key: 'email', value: 'Email' },
    linkedinMessage: { key: 'linkedin_message', value: 'LinkedIn Message' },
    linkedinMail: { key: 'linkedin_inmail', value: 'LinkedIn InMail' },
    linkedinConnection: {
      key: 'linkedin_connection_request',
      value: 'LinkedIn Connection Request',
    },
  };

  const fields = ['greeting', 'introduction', 'body', 'message', 'call_to_action', 'closing'];

  const fetchAIContent = (values, numberOfEmails = 2, index) => {
    try {
      let responseCounter = 0;
      const responses = [];

      const handleSuccess = (res) => {
        const response = res?.json || {};

        const AIResponse = Object.keys(response).reduce((newResponse, key) => {
          newResponse[key.toLowerCase()] = response[key];
          return newResponse;
        }, {});

        const { subject, subjectline, subject_line } = AIResponse;

        responses.push({
          body: parseHTMLFromObject(AIResponse, fields),
          subject: subject ?? subjectline ?? subject_line ?? '',
          payload: res,
        });

        responseCounter += 1;

        if (responseCounter === numberOfEmails) {
          setLoading(false);
          setRegenerateLoading(-1);
          setStep(1);
          if (numberOfEmails === 2) {
            setAIResponse(responses);
          } else {
            const cloneAIResponse = [...aiResponse];
            cloneAIResponse[index] = responses[0];
            setAIResponse(cloneAIResponse);
          }
        }
      };

      Array.from({ length: numberOfEmails }, (_, index) => {
        emit(
          'pipeline:execute',
          JSON.stringify({
            slug: 'create-email',
            payload: values,
          }),
        );
      });

      socket.on('pipeline:execute:success', handleSuccess);

      const handleDisconnect = () => {
        setLoading(false);
        toast.warning('AI service temporarily unavailable');
        socket.off('disconnect', handleDisconnect);
      };

      socket.on('disconnect', handleDisconnect);

      socket.on('pipeline:execute:error', () => {
        setLoading(false);
      });
    } catch (e) {
      toast.error('AI generation failed', 'tc');
      setTimeout(() => {
        setStep(0);
        setLoading(false);
      }, 3000);
    }
  };

  const prepareFinalValue = (
    values,
    isEmailForm,
    selectedValue,
    formValues,
    aiGenerationType,
    props,
  ) => {
    let finalValue = {
      Audience: values?.audience || formValues?.audience,
      Additional_Instructions: values?.additionalInstructions || formValues?.additionalInstructions,
      Purpose: values?.purpose || formValues?.purpose,
      Type: isEmailForm
        ? aiGenerationType.email.value
        : aiGenerationType[props?.linkedinType]?.value,
    };

    if (!isEmailForm) {
      finalValue.purpose = values?.purpose || formValues?.purpose || '';
      finalValue.furtherInstruction =
        values?.additionalInstructions || formValues?.additionalInstructions || '';
    }

    if (selectedValue?.id) {
      const { creationType, voicePrompt, params } = selectedValue;
      const parseVoice = voicePrompt && isJson(voicePrompt) ? JSON.parse(voicePrompt) : '';
      const parsedVoicePrompt = parseVoice?.tovGuidelines || {};

      if (creationType === 'ai') {
        if (parsedVoicePrompt) {
          finalValue = {
            ...finalValue,
            style: parsedVoicePrompt?.style || parsedVoicePrompt?.Style,
            tone: parsedVoicePrompt?.tone || parsedVoicePrompt?.Tone,
            pacing: parsedVoicePrompt?.pacing || parsedVoicePrompt?.Pacing,
          };
        }
      } else {
        finalValue = {
          ...finalValue,
          style: params?.style,
          tone: params?.tone?.join(','),
          pacing: parsedVoicePrompt?.pacing || parsedVoicePrompt?.Pacing || '',
        };

        if (!isEmailForm) {
          finalValue.toneOfVoice = params?.tone?.join(',');
        }
      }
    }

    return finalValue;
  };

  const generateAIContent = async (values) => {
    setLoading(true);
    setFormValues({ ...values });
    setAIPromptData(values);

    const finalValue = await prepareFinalValue(
      values,
      isEmailForm,
      selectedValue,
      formValues,
      aiGenerationType,
      props,
    );

    fetchAIContent(finalValue);
  };

  const regenerateContent = async (index) => {
    setRegenerateLoading(index);

    const finalValue = await prepareFinalValue(
      {},
      isEmailForm,
      selectedValue,
      formValues,
      aiGenerationType,
      props,
    );

    fetchAIContent(finalValue, 1, index);
  };

  const insertSelectedTemplate = () => {
    props?.setSelectedAiResponse(aiResponse[selectedOption]);
    props?.setOpenAIWriter(false);
  };

  return (
    <>
      <Dialog
        sx={{
          maxWidth: { xs: '900px', md: 'calc(100% - 430px)' },
          width: '100%',
        }}
        PaperProps={{
          sx: {
            borderRadius: '15px',
            maxWidth: '800px',
            width: '100%',
          },
        }}
        maxWidth="md"
        open={props?.open}
        disableEscapeKeyDown
        hideBackdrop
        onClose={handleClose}
      >
        {(props?.loading || loading) && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1,
            }}
            open={props?.loading || loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box sx={{ overflowY: 'auto' }}>
          <Box>
            {step === 1 ? (
              <AIWriterFormHeader
                options={getWriteFromHeaderOptions}
                handleClose={handleClose}
                step={step}
                showSelect={props?.showSelect}
                setStep={setStep}
                classes={classes}
                selectedEmailFormType={selectedEmailFormType}
                setSelectedEmailFormType={setSelectedEmailFormType}
                {...props}
              />
            ) : null}
            {step === 0 ? (
              <EmailWriterModal
                selectedValue={selectedValue}
                loadingPage={loadingPage}
                fetchVoiceData={fetchVoiceData}
                handleClose={handleClose}
                aiPromptFormResponse={aiPromptFormResponse}
                generateAIContent={generateAIContent}
                options={options}
                setSelectedValue={setSelectedValue}
                aiPromptData={aiPromptData}
              />
            ) : null}
            {step === 1 ? (
              <Box className={classes.cardContent}>
                {step === 1 ? (
                  <AIPromptView
                    aiResponse={aiResponse}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    classes={classes}
                    refetchContent={regenerateContent}
                    loading={loading}
                    isEmailForm={isEmailForm}
                    refetchLoading={regenerateLoading}
                  />
                ) : null}
              </Box>
            ) : null}
            {step !== 0 && (
              <Box sx={{ width: '100%' }}>
                <Box className={classes.cardFooter}>
                  <AIInsertButton
                    onClick={() => insertSelectedTemplate()}
                    classes={classes}
                    step={step}
                    disabled={!selectedOption?.toString()}
                    label={'Insert'}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default AIWriteForm;
